body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  padding: 30px;
}

.Header {
  border-bottom: 1px solid #eeeeee;
}

.Home {
  max-width: 1100px;
  margin: auto;
  justify-content: center;
  align-items: center;
}

.Home img {
  max-height: 100%;
  max-width: 100%;
}

.HeroTitle {
  color: #000000;
  font-size: 36px;
  font-family: 'Nunito', sans-serif;
  line-height: 42px;
}

h2.HeroTitle {
  font-weight: 400;
}

h1.HeroTitle {
  font-weight: 700;
}

.ios-button {
  margin-top: 20px;
}
